<template>
  <b-col cols="12 p-0">
      <b-card :class="is_detail_network ? 'border-box-analytics' : ''">
        <b-card-header>
          <b-card-title>{{$t('instagram.audienceInterest')}}</b-card-title>
        </b-card-header>
        <b-card-body class="text-start">
          <b-row>
            <b-col :class="`p-1 d-flex flex-row ${is_detail_network ? 'col-12 col-lg-6' : 'col-12 col-md-6 col-lg-4'}`" v-for="(item, index) in this.audienceData.interests.slice(0,maxInterest)" :key="index">
                <b-card-text class="col-9"> <b-img :class="`${getColor(item[1])} mr-1`" :src="getIconInterest(item[0])"/> {{$t(`instagram.${item[0]}`)}}:</b-card-text>
                <span class="col-3">{{round(item[1] * 100)}}%</span>
            </b-col>
          </b-row>
          <div v-if="maxInterest === 8" class="text-start">
            <b-collapse id="more-interests" v-model="visible"> 
              <b-row>
                <b-col :class="`p-1 d-flex flex-row ${is_detail_network ? 'col-12 col-lg-6' : 'col-12 col-md-6 col-lg-4'}`" v-for="(item, index) in this.audienceData.interests.slice(maxInterest,max)" :key="index">
                  <b-card-text class="col-9"> <b-img :class="getColor(item[1])" class="mr-1" :src="getIconInterest(item[0])"/> {{$t(`instagram.${item[0]}`)}}:</b-card-text>
                  <span class="col-3">{{round(item[1] * 100)}}%</span>
                </b-col>
              </b-row>
            </b-collapse>
            <div class="button-center">
            <b-button
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="more-interests"
            @click="visible = !visible" variant="outline-primary">
              {{visible ? $t('instagram.seeLess') : $t('instagram.seeMore')}}
            </b-button>
          </div>
          </div>
        </b-card-body>
      </b-card>
  </b-col>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BButton, BCollapse, BCardText, BImg} from 'bootstrap-vue'
import { getIconInterest } from '@/libs/utils/icons'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BButton,
    BCollapse,
    BImg
  },
  computed: {
    maxInterest() {
      if (this.max >= 8) return 8
      return this.max
    }
  },
  props: {
    audienceData: {
      required: true
    },
    is_detail_network: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getColor (level) {
      if (level <= .3) return 'img-interest-danger'
      if (level < .8 && level >= .7) return 'img-interest-primary'
      if (level < .9 && level >= .8) return 'img-interest-info'
      if (level >= .9) return 'img-interest-success'
      return 'img-interest-warning'
    },
    round (num) {
      return Math.round(num)
    },
    getInterest (interest) {
      return interest.replaceAll(' ', '')
    }
  },
  data () {
    return {
      max: this.audienceData.interests.length,
      visible: false,
      getIconInterest,
    }
  },
}
</script>

<style >
.img-interest-success {
  width: 25px;
  filter: brightness(1) invert(1) sepia(1) hue-rotate(80deg) saturate(10000%);
}
.img-interest-info {
  width: 25px;
  filter: brightness(1) invert(1) sepia(1) hue-rotate(170deg) saturate(10000%);
}
.img-interest-primary {
  width: 25px;
  filter: brightness(1) invert(1) sepia(1) hue-rotate(200deg) saturate(10000%);
}
.img-interest-warning {
  width: 25px;
  filter: brightness(1) invert(1) sepia(1) hue-rotate(315deg) saturate(10000%);
}
.img-interest-danger {
  width: 25px;
  filter: brightness(1) invert(1) sepia(1) hue-rotate(290deg) saturate(10000%);
}
.button-center{
  text-align: center;
}
</style>